
import { Component, Vue } from "vue-property-decorator";
import AuthGateway from "@/auth/services/AuthGateway";
import { mixins } from "vue-class-component";
import CheckEmailMixin from "@/mixin/CheckEmailMixin";
import TokenStorage from "@/auth/services/TokenStorage";
import AuthConfig from "@/auth/config/AuthConfig";
import { RestType } from "@/auth/config/RestType";
import EmailField from "@/auth/pages/components/EmailField.vue";
import AuthCard from "@/auth/pages/components/AuthCard.vue";
import { LoginRoute } from "@/router/Routes";
import { BRow } from "bootstrap-vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import Config from "@/constants/Config";

@Component({
  components: {
    AuthCard,
    EmailField,
    BaseButton,
    BRow,
  },
})
export default class ForgotPassword extends mixins(Vue, CheckEmailMixin) {
  message = "";
  loginRoutePath = LoginRoute.path;

  setEmail(newVal: string): void {
    this.message = "";
    this.email = newVal;
  }

  get isButtonEnabled(): boolean {
    return this.emailIsValid();
  }

  onSubmit(): void {
    if (Config.DEV_MODE) {
      alert("not available in DEV_MODE");

      return;
    }

    this.message =
      "You should receive a link in the address above in a few moments.<br>Please open that link to reset your password.";

    TokenStorage.clear();
    this.$store.commit("auth/setAuthState");

    const body = {
      loginId: this.email,
      sendForgotPasswordEmail: true,
      applicationId: AuthConfig.AUTH_APPLICATION_ID,
    };

    const headers = {
      "Content-Type": "application/json",
      "X-FusionAuth-TenantId": AuthConfig.AUTH_TENANT_ID,
    };

    AuthGateway.sendRequest(
      AuthConfig.AUTH_GATEWAY_BASE_URL,
      RestType.POST,
      AuthConfig.ForgotPasswordPath,
      headers,
      body
    ).catch(() => {
      this.message =
        "An error occurred! Please try again. If it persists, please contact us.";
    });
  }
}
