
import { Component, Vue } from "vue-property-decorator";
import AuthGateway from "@/auth/services/AuthGateway";
import TokenStorage from "@/auth/services/TokenStorage";
import AuthConfig from "@/auth/config/AuthConfig";
import { RestType } from "@/auth/config/RestType";
import PasswordField from "@/auth/pages/components/PasswordField.vue";
import AuthCard from "@/auth/pages/components/AuthCard.vue";
import { BRow } from "bootstrap-vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { LoginRoute, UpdatePasswordRoute } from "@/router/Routes";
import Config from "@/constants/Config";
import router from "@/router";

@Component({
  components: { AuthCard, BaseButton, PasswordField, BRow },
})
export default class ChangePassword extends Vue {
  newPassword = "";
  repeatPassword = "";
  message = "";
  isButtonEnabled = false;
  loginRoutePath = LoginRoute.path;

  get isUpdatePasswordRoute(): boolean {
    return router.currentRoute.path === UpdatePasswordRoute.path;
  }

  updateNewPassword(newValue: string): void {
    this.newPassword = newValue;
    this.checkNewPasswords();
  }

  updateRepeatedPassword(newValue: string): void {
    this.repeatPassword = newValue;
    this.checkNewPasswords();
  }

  checkNewPasswords(): void {
    this.isButtonEnabled = false;

    if (!this.isPasswordNotEmpty()) {
      this.message = "Password field must be filled";
    } else if (!this.isPasswordAboveMinimumLength()) {
      this.message = "You need at least 8 characters";
    } else if (!this.isPasswordBelowMaximumLength()) {
      this.message = "Maximum length is 256 characters";
    } else if (!this.isUppercaseIncluded()) {
      this.message = "Password needs to contain at least one upper case letter";
    } else if (!this.isLowercaseIncluded()) {
      this.message = "Password needs to contain at least one lower case letter";
    } else if (!this.isNumberIncluded()) {
      this.message = "Password needs to contain at least one number";
    } else if (!this.isNonAlphaNumericCharacterIncluded()) {
      this.message =
        "Password needs to contain at least one non-alphanumeric character";
    } else if (!this.isRepeatPasswordNotEmpty()) {
      this.message = "Both password fields must be filled";
    } else if (!this.doPasswordsMatch()) {
      this.message = "Passwords do not match!";
    } else {
      this.message = "";
      this.isButtonEnabled = true;
    }
  }

  isPasswordNotEmpty(): boolean {
    return this.newPassword !== "";
  }

  isRepeatPasswordNotEmpty(): boolean {
    return this.repeatPassword !== "";
  }

  isPasswordAboveMinimumLength(): boolean {
    return this.newPassword.length >= 8;
  }

  isPasswordBelowMaximumLength(): boolean {
    return this.newPassword.length <= 256;
  }

  isUppercaseIncluded(): boolean {
    return /[A-Z]/.test(this.newPassword);
  }

  isLowercaseIncluded(): boolean {
    return /[a-z]/.test(this.newPassword);
  }

  isNumberIncluded(): boolean {
    return /\d/.test(this.newPassword);
  }

  isNonAlphaNumericCharacterIncluded(): boolean {
    return !/^[a-zA-Z0-9]+$/.test(this.newPassword);
  }
  doPasswordsMatch(): boolean {
    return this.newPassword === this.repeatPassword;
  }

  onSubmit(): void {
    this.checkNewPasswords();

    if (!this.isMessageFieldEmpty) {
      return;
    }

    if (Config.DEV_MODE) {
      alert("not available in DEV_MODE");

      return;
    }

    // @FIXME route parameter have different namings inside the emails on prod and testing --> SPC-1042
    const changePasswordId = this.$route.query.cpid || this.$route.query.cp_id;

    if (typeof changePasswordId === "string") {
      this.changePassword(changePasswordId);
    } else {
      this.message =
        "There seems to be a problem with the URL.<br>Please re-start the process";
    }
  }

  get isMessageFieldEmpty(): boolean {
    return this.message === "";
  }

  private changePassword(changePasswordId: string): Promise<void> {
    TokenStorage.clear();
    this.$store.commit("auth/setAuthState");

    const headers = {
      "Content-Type": "application/json",
      "X-FusionAuth-TenantId": AuthConfig.AUTH_TENANT_ID,
    };

    const finalPath = AuthConfig.ChangePasswordPath + changePasswordId;

    const data = { password: this.newPassword };

    return AuthGateway.sendRequest(
      AuthConfig.AUTH_GATEWAY_BASE_URL,
      RestType.POST,
      finalPath,
      headers,
      data
    )
      .then(() => {
        this.$router.push({ name: LoginRoute.name });
      })
      .catch(() => {
        this.message =
          "An internal server error occurred.<br>Please re-start the process or contact us.";
      });
  }
}
