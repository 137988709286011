export default {
  AuthGatewayUrl: process.env.VUE_APP_AUTH_BASE_URL ?? "",
  AuthApplicationId: process.env.VUE_APP_AUTH_APPLICATION_ID ?? "",
  AuthTenantApiKey: process.env.VUE_APP_AUTH_TENANT_API_KEY ?? "",
  AuthTenantId: process.env.VUE_APP_AUTH_TENANT_ID ?? "",
  ApiGatewayUrl: process.env.VUE_APP_API_GATEWAY_URL ?? "",
  ContactEmailAddress: "mailto:pe-support@right-basedonscience.de",
  PECSVTemplateLink:
    (process.env.VUE_APP_API_GATEWAY_URL ?? "") +
    "/assets/csv/pe/pe_sample_file.csv",
  PEMarketValueCSVTemplateLink:
    (process.env.VUE_APP_API_GATEWAY_URL ?? "") +
    "/assets/csv/pe/pe_sample_file_with_market_value.csv",
  // > 4 requests are to much for the database (maybe with some infrastructure improvements this can be set higher
  MAX_CONCURRENT_REQUESTS: 4,
  REQUEST_CHUNK_SIZE: 25,

  DEV_MODE: getBoolean(process.env.VUE_APP_DEV_MODE, false),
  DEV_TOKEN: "DEVtoken",

  //Vue Numeric settings
  SEPARATOR_THOUSAND: "space",
  SEPARATOR_DECIMAL: ".",
  PRECISION_DECIMAL: 2,

  DEFAULT_CURRENCY: "USD",
};

//this is needed because .env only delivers strings
function getBoolean(
  booleanString: string | undefined,
  defaultValue: boolean
): boolean {
  if (booleanString) {
    return booleanString.toLowerCase() === "true";
  }

  return defaultValue;
}
