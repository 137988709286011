export default class LoginRequest {
  loginId: string;
  password: string;
  applicationId: string;

  constructor(email: string, password: string, applicationId: string) {
    this.loginId = email;
    this.password = password;
    this.applicationId = applicationId;
  }
}
