export interface PERoute {
  path: string;
  name: string;
}

export const FallbackRoute: PERoute = {
  path: "/*",
  name: "Fallback",
};

export const LandingRoute: PERoute = {
  path: "/",
  name: "Landing",
};

export const AnalyticsRoute: PERoute = {
  path: "/analytics",
  name: "Analytics",
};

export const OverviewRoute: PERoute = {
  path: "/overview",
  name: "Overview",
};

export const LoginRoute: PERoute = {
  path: "/login",
  name: "Login",
};

export const ForgotPasswordRoute: PERoute = {
  path: "/forgot-password",
  name: "ForgotPassword",
};

export const ChangePasswordRoute: PERoute = {
  path: "/change-password",
  name: "ChangePassword",
};

export const UpdatePasswordRoute: PERoute = {
  path: "/update-password",
  name: "UpdatePassword",
};

export const RegisterRoute: PERoute = {
  path: "/register",
  name: "Register",
};

export const CompleteVerificationRoute: PERoute = {
  path: "/verify",
  name: "CompleteVerification",
};

export const FAQRoute: PERoute = {
  path: "/faq",
  name: "FAQ",
};

export const PlansRoute: PERoute = {
  path: "/plans",
  name: "Plans",
};
