import Vue from "vue";
import VueRouter, {
  NavigationGuardNext,
  RawLocation,
  Route,
  RouteConfig,
} from "vue-router";
import LandingPage from "@/pages/landing/LandingPage.vue";
import TokenStorage from "@/auth/services/TokenStorage";
import store from "@/store/main";
import {
  AnalyticsRoute,
  ChangePasswordRoute,
  CompleteVerificationRoute,
  FallbackRoute,
  FAQRoute,
  ForgotPasswordRoute,
  LandingRoute,
  LoginRoute,
  OverviewRoute,
  PlansRoute,
  RegisterRoute,
  UpdatePasswordRoute,
} from "@/router/Routes";
import Analytics from "@/pages/analytics/AnalyticsPage.vue";
import OverviewPage from "@/pages/overview/OverviewPage.vue";
import Login from "@/auth/pages/Login.vue";
import CompleteVerification from "@/auth/pages/CompleteVerification.vue";
import ForgotPassword from "@/auth/pages/ForgotPassword.vue";
import ChangePassword from "@/auth/pages/ChangePassword.vue";
import Register from "@/auth/pages/Register.vue";
import { ALL_ROLES } from "@/constants/Roles";
import FAQPage from "@/pages/faq/FAQPage.vue";
import PlansPage from "@/pages/plans/PlansPage.vue";
import {
  hasRefreshToken,
  noAuthIsRequired,
  removeTrackingForStaff,
  routeIsClosedForLoggedUsers,
  triggerTokenRefresh,
  userFulfillsRouteRequirements,
} from "@/router/RouteGuardHelper";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: LandingRoute.path,
    name: LandingRoute.name,
    component: LandingPage,
  },
  {
    path: AnalyticsRoute.path,
    name: AnalyticsRoute.name,
    component: Analytics,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: OverviewRoute.path,
    name: OverviewRoute.name,
    component: OverviewPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: LoginRoute.path,
    name: LoginRoute.name,
    component: Login,
    meta: {
      disabledIfLoggedIn: true,
    },
  },
  {
    path: ForgotPasswordRoute.path,
    name: ForgotPasswordRoute.name,
    component: ForgotPassword,
    meta: {
      disabledIfLoggedIn: true,
    },
  },
  {
    path: ChangePasswordRoute.path,
    name: ChangePasswordRoute.name,
    component: ChangePassword,
    meta: {
      disabledIfLoggedIn: true,
    },
  },
  {
    path: UpdatePasswordRoute.path,
    name: UpdatePasswordRoute.name,
    component: ChangePassword,
    meta: {
      disabledIfLoggedIn: true,
    },
  },
  {
    path: RegisterRoute.path,
    name: RegisterRoute.name,
    component: Register,
    meta: {
      disabledIfLoggedIn: true,
    },
  },
  {
    path: CompleteVerificationRoute.path,
    name: CompleteVerificationRoute.name,
    component: CompleteVerification,
  },
  {
    path: FAQRoute.path,
    name: FAQRoute.name,
    component: FAQPage,
  },
  {
    path: PlansRoute.path,
    name: PlansRoute.name,
    component: PlansPage,
  },

  //HAS TO BE LAST ON LIST
  {
    path: FallbackRoute.path,
    name: FallbackRoute.name,
    component: LandingPage,
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: function (to: Route) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  removeTrackingForStaff();

  if (noAuthIsRequired(to)) {
    handleNoAuthRequiredRoute(to, next);
  } else {
    handleAuthRequiredRoute(next, to);
  }
});

function handleNoAuthRequiredRoute(to: Route, next: NavigationGuardNext): void {
  if (routeIsClosedForLoggedUsers(to)) {
    // This is in case a logged user tries to navigate to a login page or similar
    // Re-route a LOGGED user to
    next({ name: OverviewRoute.name });
  } else {
    // if the route should not be disabled if user not logged, we just go to it
    next();
  }
}

function handleAuthRequiredRoute(
  next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void,
  to: Route
): void {
  const isUserAuthenticated = TokenStorage.isTokenValid();

  if (isUserAuthenticated) {
    if (routeIsClosedForLoggedUsers(to)) {
      // Route should not be shown for logged-in users, so we re-route them
      next({ name: OverviewRoute.name });
    } else if (userFulfillsRouteRequirements(to)) {
      // No role restriction OR the user has the required role
      // we allow them to proceed
      next();
    } else {
      next({ name: LandingRoute.name });
    }
  } else {
    if (hasRefreshToken()) {
      // If we still have a refresh token we refresh the token and upon that we
      // move on to the next route
      triggerTokenRefresh().then(() => next());
    } else {
      // if auth is required but user is not authenticated we route them to login
      store.dispatch("auth/logout");
      next({ name: LoginRoute.name });
    }
  }
}

export default router;
