import Config from "@/constants/Config";

export default class AuthConfig {
  static AUTH_GATEWAY_BASE_URL = Config.AuthGatewayUrl;
  static AUTH_APPLICATION_ID = Config.AuthApplicationId;
  static AUTH_TENANT_API_KEY = Config.AuthTenantApiKey;
  static AUTH_TENANT_ID = Config.AuthTenantId;

  static LoginPath = "/api/login";
  static LogoutPath = "/api/logout";
  static JWTRefreshPath = "/api/jwt/refresh";
  static ForgotPasswordPath = "/api/user/forgot-password";
  static ChangePasswordPath = "/api/user/change-password/";
  static RegistrationPath = "/api/user/registration/";
}
