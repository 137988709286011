
import { Component, Vue } from "vue-property-decorator";
import LoginRequest from "@/auth/models/LoginRequest";
import CheckEmailMixin from "@/mixin/CheckEmailMixin";
import { mixins } from "vue-class-component";
import { BRow } from "bootstrap-vue";
import AuthConfig from "@/auth/config/AuthConfig";
import {
  ForgotPasswordRoute,
  OverviewRoute,
  RegisterRoute,
  UpdatePasswordRoute,
} from "@/router/Routes";
import AuthCard from "./components/AuthCard.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import PasswordField from "./components/PasswordField.vue";
import EmailField from "./components/EmailField.vue";
import AuthGateway from "@/auth/services/AuthGateway";
import { RestType } from "@/auth/config/RestType";
import { AxiosResponse } from "axios";
import TokenStorage from "@/auth/services/TokenStorage";
import router from "@/router";
import store from "@/store/main";
import Config from "@/constants/Config";
import DevModeGateway from "@/services/DevModeGateway";

@Component({
  components: {
    BRow,
    AuthCard,
    BaseButton,
    EmailField,
    PasswordField,
  },
})
export default class Login extends mixins(Vue, CheckEmailMixin) {
  password = "";
  message = "";
  passwordRoutePath = ForgotPasswordRoute.path;
  registerRoutePath = RegisterRoute.path;

  setEmail(newVal: string): void {
    this.email = newVal;
  }

  setPassword(newVal: string): void {
    this.password = newVal;
  }

  get isButtonEnabled(): boolean {
    return this.emailIsValid() && this.password !== "";
  }

  onSubmit(): void {
    this.message = "";

    const loginRequest = new LoginRequest(
      this.email,
      this.password,
      AuthConfig.AUTH_APPLICATION_ID
    );
    let backendResponse;

    if (Config.DEV_MODE) {
      backendResponse = DevModeGateway.login();
    } else {
      backendResponse = AuthGateway.sendRequest(
        AuthConfig.AUTH_GATEWAY_BASE_URL,
        RestType.POST,
        AuthConfig.LoginPath,
        {},
        loginRequest
      );
    }

    backendResponse
      .then((response: AxiosResponse) => {
        const loginJson: Record<string, string> = response && response.data;

        // if password change is required (fusionauth response code 203), we route to change password page with change password id as query param
        if (response.status === 203) {
          router.push({
            path: UpdatePasswordRoute.path,
            query: { change_password_id: response.data.changePasswordId },
          });
        }

        Login.checkLoginResponse(response);

        const { refreshToken, token } = loginJson;

        TokenStorage.storeToken(token);
        TokenStorage.storeRefreshToken(refreshToken);

        if (!TokenStorage.isEmailVerified()) {
          router.push({ name: RegisterRoute.name });
        } else {
          store.commit("auth/setAuthState");
          // clear the state to set Role-dependant variables correctly after login
          this.$store.dispatch("clearAllStores");
          router.push({ name: OverviewRoute.name });
        }
      })
      .catch((err) => {
        if (err.message && !err.isAxiosError) {
          this.message = err.message;
        } else {
          this.message = "Login failed. Check your Credentials and try again.";
        }
      });
  }

  private static checkLoginResponse(response: AxiosResponse): void {
    // codes from: https://fusionauth.io/docs/apis/login
    switch (response.status) {
      case 200:
        break;
      case 202: {
        throw Error(
          "It seems that you don't have a registration for this application. Please contact us."
        );
      }

      case 212: {
        throw Error("Login failed: You must verify your email first");
      }

      case 404: {
        throw Error(
          "Login failed: Check your username and password and try again."
        );
      }

      case 410: {
        throw Error("Login failed: Your user is expired.");
      }

      default: {
        throw Error(
          `Login failed: Please contact the support team(Error Code: ${response?.status}}}`
        );
      }
    }
  }
}
