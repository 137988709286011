
import { Component, Vue } from "vue-property-decorator";
import ValuesHeader from "@/pages/main/components/header/portfoliovalues/ValuesHeader.vue";
import ScenarioSelectorHeader from "@/pages/main/components/header/ScenarioSelectorHeader.vue";
import SignInButton from "@/pages/shared/components/buttons/SignInButton.vue";
import ContactButton from "@/pages/shared/components/buttons/ContactButton.vue";
import Toolbar from "@/pages/main/components/toolbar/Toolbar.vue";
import Sidebar from "@/pages/main/components/header/Sidebar.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import ProfileDropdownMenu from "@/pages/main/components/header/ProfileDropdownMenu.vue";
import { mixins } from "vue-class-component";
import HeaderCheckMixin from "@/mixin/HeaderCheckMixin";
import Footer from "@/pages/main/components/Footer.vue";
import AppUpdateAlert from "@/pages/main/components/alert/AppUpdateAlert.vue";
import {
  ChangePasswordRoute,
  FAQRoute,
  ForgotPasswordRoute,
  LandingRoute,
  LoginRoute,
  PlansRoute,
  RegisterRoute,
} from "@/router/Routes";
import { BCol, BOverlay } from "bootstrap-vue";
import AssetMixin from "../../mixin/AssetMixin";
import UserMixin from "@/mixin/UserMixin";
import NewPortfolioModal from "@/pages/main/components/toolbar/new-portfolio/NewPortfolioModal.vue";
import ConfigurationModal from "@/pages/main/components/toolbar/configuration/ConfigurationModal.vue";
import NewSecurityModal from "@/pages/main/components/toolbar/new-security/NewSecurityModal.vue";
import SecuritiesDeletionModal from "@/pages/main/components/toolbar/delete-security/SecuritiesDeletionModal.vue";
import UpgradeModal from "@/pages/main/components/modals/UpgradeModal.vue";
import CSVUploadFeedbackModal from "@/pages/main/components/modals/CSVUploadFeedbackModal.vue";
import ChangelogAvailableAlert from "@/pages/main/components/alert/ChangelogAvailableAlert.vue";
import ChangeLogModal from "@/pages/faq/modals/ChangeLogModal.vue";

@Component({
  components: {
    AppUpdateAlert,
    BaseButton,
    ChangeLogModal,
    ChangelogAvailableAlert,
    ConfigurationModal,
    ContactButton,
    CSVUploadFeedbackModal,
    Footer,
    NewSecurityModal,
    NewPortfolioModal,
    ProfileDropdownMenu,
    SecuritiesDeletionModal,
    Toolbar,
    ScenarioSelectorHeader,
    Sidebar,
    SignInButton,
    UpgradeModal,
    ValuesHeader,
    BCol,
    BOverlay,
  },
})
export default class App extends mixins(
  Vue,
  HeaderCheckMixin,
  AssetMixin,
  UserMixin
) {
  showOverlay = true;
  showCompactMode = false;
  registerRoutePath = RegisterRoute.path;
  landingRoutePath = LandingRoute.path;
  isScrolledToTop = true;

  showFooterPaths = [
    FAQRoute.path,
    RegisterRoute.path,
    ChangePasswordRoute.path,
    ForgotPasswordRoute.path,
    LoginRoute.path,
    LandingRoute.path,
    PlansRoute.path,
  ];

  get showFooter(): boolean {
    return this.showFooterPaths.includes(this.$route.path);
  }

  get isLandingRoute(): boolean {
    return this.$route.path === LandingRoute.path;
  }

  get isLandingPage(): boolean {
    const currentRoutePath = this.$route.path;

    return this.landingRoutePath === currentRoutePath;
  }

  created(): void {
    window.addEventListener("resize", this.showCompactedMenu);
    this.showCompactedMenu();
    window.addEventListener("scroll", this.checkIfIsScrolledToTop);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.showCompactedMenu);
    window.removeEventListener("scroll", this.checkIfIsScrolledToTop);
  }

  showCompactedMenu(): void {
    this.showCompactMode = window.matchMedia("(max-width: 1199px)").matches;
  }

  checkIfIsScrolledToTop(): void {
    let top = document.documentElement.scrollTop;
    this.isScrolledToTop = top === 0;
  }
}
